import { Dialog, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import { Fragment, useEffect, useState } from "react"

import { useRouter } from "next/router"
import useIndex from "src/server/queryHooks/useIndex"
import { ICategory } from "src/types/commonTypes"
import Checkbox from "../common/form-controll/Checkbox/DropdownCheckbox"
import Typography from "../typography"
import { searchbox_filter_generator } from "../../../utils/data/filter-data"
import { SearchBoxFilterButton } from "../../pages/index/SearchBox/SearchBox.styles"
import { DialogBody, DialogContaier, DialogFooter, DialogHeader, DialogPosition } from "./SearchboxFilterModal.styles"
import Button from "src/views/components/common/Button/Button"
import FilterRangeSliderBig from "src/views/components/RangeSlider/FilterRangeSliderBig"
import RangeSliderContainer from "src/views/components/RangeSlider/RangeSliderContainer"
import MobileSearchDropdown from "src/views/pages/index/SearchBox/SearchInputDropdowns/mobileSearchDropdown"
import SearchBox from "src/views/pages/index/SearchBox/SearchBox"
import { IOptionJobType } from "src/utils/data/phisical-register-data"
import { useSearch } from "src/views/pages/index/SearchBoxNew/hooks/SearchContext"

interface Props {
  selectedFilters?: any
  setSelectedFilters?: any
  isMobileOpen?: boolean
  setIsMobileOpen?: any
  style_type?: string
}
const SearchboxFilterModal = ({
  selectedFilters,
  setSelectedFilters,
  isMobileOpen,
  setIsMobileOpen,
  style_type
}: Props) => {
  const router = useRouter()
  const [showAllCats, setShowAllCats] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { mainPageDataV2 } = useIndex()

  const handleFilterChange = (filterName: string, value: null | string | string[], isSelected: boolean) => {
    setSelectedFilters((prevState: any) => {
      const currentValues = prevState[filterName] || []

      if (isSelected) {
        return {
          ...prevState,
          [filterName]: currentValues.includes(value) ? currentValues : [...currentValues, value]
        }
      } else {
        return {
          ...prevState,
          [filterName]: currentValues.filter((item: any) => item !== value)
        }
      }
    })
  }

  const numberOfSelectedFilters = Object.values(selectedFilters).reduce(
    (sum, currentArray: any) => sum + currentArray.length,
    0
  )

  const { filters } = useSearch()

  const [selectedCompanyId, setSelectedCompanyID] = useState<number | null>(null)
  const [selectedKeyword, setSelectedKeyword] = useState<string | null>(null)
  const [query, setQuery] = useState("")
  const handeKeywordOptionClick = (option: any) => {
    if (option.type === "company") {
      setSelectedCompanyID(option.company_id)
      setSelectedKeyword(null)
    }

    if (option.type === "keyword") {
      setSelectedKeyword(option.value)
      setSelectedCompanyID(null)
    }
  }

  const [selectedJobType, setSelectedJobType] = useState<IOptionJobType[]>([])
  const [selectedLocations, setSelectedLocations] = useState<any>([])

  const performSearch = (keyword?: string) => {
    const queryParams = new URLSearchParams()

    for (const filterName in selectedFilters) {
      if (Array.isArray(selectedFilters[filterName])) {
        queryParams.append(filterName, selectedFilters[filterName].join(","))
      } else {
        queryParams.append(filterName, selectedFilters[filterName])
      }
    }

    queryParams.append("job_types", selectedJobType.map(jobType => jobType.value).join(","))
    queryParams.append("countries", selectedLocations.join(","))
    queryParams.append("company_id", selectedCompanyId?.toString() || "")
    if (keyword && typeof keyword === "string") {
      queryParams.append("keyword", keyword)
    } else {
      queryParams.append("keyword", selectedKeyword || query || "")
    }

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        queryParams.append(key, filters[key])
      }
    }
    router.push({ pathname: "/vacancy", search: queryParams.toString() })
  }

  const handleBack = () => {
    setIsMobileOpen(false)
  }

  const handleBtnClick = () => {
    if (window.innerWidth < 736) {
      setIsMobileOpen(true)
    } else {
      setIsOpen(true)
    }
  }

  const handleDetailFilter = () => {
    setIsOpen(true)
  }

  const search = () => {
    setIsOpen(false)
    setIsMobileOpen(false)
    const queryParams = new URLSearchParams()

    for (const filterName in selectedFilters) {
      if (Array.isArray(selectedFilters[filterName])) {
        queryParams.append(filterName, selectedFilters[filterName].join(","))
      } else {
        queryParams.append(filterName, selectedFilters[filterName])
      }
    }

    queryParams.append("job_types", selectedJobType.map(jobType => jobType.value).join(","))
    queryParams.append("countries", selectedLocations.join(","))

    if (selectedKeyword) {
      queryParams.append("keyword", selectedKeyword)
    }
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        queryParams.append(key, filters[key])
      }
    }

    router.push({ pathname: "/vacancy", search: queryParams.toString() })
  }

  const clearSearches = () => {
    setSelectedJobType([])
    setSelectedKeyword("")
    setSelectedLocations([])
  }

  return (
    <>
      {style_type != "vacancy" && (
        <SearchBoxFilterButton onClick={() => handleBtnClick()}>
          <Image src="/images/tune.svg" width={22} height={22} alt="tune" />
          {!!numberOfSelectedFilters && (
            <div className="absolute -right-0.5 -top-0.5 flex h-[17px] w-[17px] items-center justify-center rounded-full bg-error-100">
              <Typography type="mini" weight="medium" className="text-white">
                {numberOfSelectedFilters}
              </Typography>
            </div>
          )}
        </SearchBoxFilterButton>
      )}
      <Transition appear show={isMobileOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsMobileOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <DialogPosition className="m-0 p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="ease-in duration-500"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel className="max-h-full rounded-none" as={DialogContaier}>
                <Dialog.Description as={DialogBody} className={"styled-scrollbar mobile-search h-full px-4"}>
                  <MobileSearchDropdown
                    style_type={"main"}
                    setSelectedJobType={setSelectedJobType}
                    selectedJobType={selectedJobType}
                    setSelectedLocationsParams={setSelectedLocations}
                    selectedLocationsParams={selectedLocations}
                    performSearch={performSearch}
                    handeKeywordOptionClick={handeKeywordOptionClick}
                    handleBack={handleBack}
                    selectedKeyword={selectedKeyword}
                    handleDetailFilter={handleDetailFilter}
                  />
                </Dialog.Description>
                <DialogFooter className="custom-shadow">
                  <Typography
                    className="cursor-pointer text-error-100"
                    weight="medium"
                    type="text"
                    onClick={clearSearches}
                  >
                    გასუფთავება {numberOfSelectedFilters ? `(${numberOfSelectedFilters})` : ""}
                  </Typography>
                  <Button onClick={search} intent="black" size="big" text={`არჩევა`} />
                </DialogFooter>
              </Dialog.Panel>
            </Transition.Child>
          </DialogPosition>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <DialogPosition className="detailed-filter-wrapper">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="ease-in duration-500"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel className="detailed-filter" as={DialogContaier}>
                <Dialog.Title as={DialogHeader}>
                  <Typography type="h4" className="grow text-center">
                    დამატებითი ფილტრები
                  </Typography>
                  <Image
                    src="/images/close.svg"
                    width={24}
                    height={24}
                    alt="close"
                    className="cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                </Dialog.Title>
                <Dialog.Description as={DialogBody} className={"styled-scrollbar"}>
                  <div className="mb-6 border-b border-neutral-20 pb-8">
                    <RangeSliderContainer />
                  </div>
                  {searchbox_filter_generator.map(section => (
                    <div key={section.id} className="py-8 pt-2">
                      <Typography type="h4">{section.title}</Typography>
                      <div className="flex flex-col gap-6 pt-6">
                        {section.subsections.map(sub => (
                          <div key={sub.id} className="flex flex-col gap-4">
                            <Typography type="text">{sub.title}</Typography>
                            <div className="flex flex-wrap gap-3">
                              {sub.options.map(option => (
                                <StatefullButton
                                  key={option.id}
                                  filter_name={sub.filter_name}
                                  handleFilterChange={handleFilterChange}
                                  is_selected={selectedFilters[sub.filter_name]?.includes(option.value) ?? false}
                                  option={option}
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                  <div className="border-t border-neutral-20 pt-8">
                    <Typography type="h4">კატეგორიები</Typography>
                    <div className="mt-3 grid grid-cols-1 md:grid-cols-3 ">
                      {mainPageDataV2 &&
                        mainPageDataV2.categories &&
                        mainPageDataV2.categories
                          .slice(0, showAllCats ? mainPageDataV2.categories.length : 9)
                          .map((category: ICategory) => (
                            <StatefullCheckbox
                              key={category.id}
                              category={category}
                              handleFilterChange={handleFilterChange}
                              isSelected={
                                selectedFilters.categories ? selectedFilters.categories.includes(category.id) : false
                              }
                            />
                          ))}
                    </div>
                    <Typography
                      type="text"
                      className={cx("mt-3 cursor-pointer underline", showAllCats ? "" : "")}
                      onClick={() => setShowAllCats(!showAllCats)}
                    >
                      {showAllCats ? "ნახე ნაკლები" : "ნახე მეტი"}
                    </Typography>
                  </div>
                </Dialog.Description>
                <DialogFooter>
                  <Typography
                    className="cursor-pointer text-error-100"
                    weight="medium"
                    type="text"
                    onClick={() => setSelectedFilters({})}
                  >
                    გასუფთავება {numberOfSelectedFilters ? `(${numberOfSelectedFilters})` : ""}
                  </Typography>
                  <Button
                    onClick={() => {
                      search()
                    }}
                    intent="black"
                    size="big"
                    disabled={filters.salary_from > filters.salary_to}
                    text={`არჩევა`}
                  />
                </DialogFooter>
              </Dialog.Panel>
            </Transition.Child>
          </DialogPosition>
        </Dialog>
      </Transition>
    </>
  )
}

const StatefullCheckbox = ({
  category,
  handleFilterChange,
  isSelected
}: {
  category: ICategory
  handleFilterChange: any
  isSelected: boolean
}) => {
  return (
    <Checkbox
      className="col-span-1 py-3 pl-0 hover:bg-transparent"
      label={category.title}
      name={category.title}
      key={category.id}
      selected={isSelected}
      noHover
      onClick={() => handleFilterChange("categories", category.id, !isSelected)}
    />
  )
}

interface StatefullButtonProps {
  option: {
    id: string | number
    label: string
    value: null | string | string[]
  }
  filter_name: string
  is_selected: boolean
  handleFilterChange: (filter_name: string, value: null | string | string[], is_selected: boolean) => void
}
const StatefullButton = ({ option, filter_name, handleFilterChange, is_selected }: StatefullButtonProps) => {
  const handleButtonClick = () => {
    handleFilterChange(filter_name, option.value, !is_selected)
  }

  return (
    <Button
      key={option.id}
      size="big"
      intent="outline_muted"
      rounded="full"
      weight="regular"
      className={cx(is_selected ? "border-secondary-100 bg-secondary-100 text-white" : "")}
      text={option.label}
      onClick={handleButtonClick}
    />
  )
}

const ModalSection = ({}) => {
  return <div></div>
}

export default SearchboxFilterModal
