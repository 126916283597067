import { Combobox, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useRef } from "react"
import Typography from "src/views/components/typography"
import useSearch from "src/server/queryHooks/useKeywordSearch"
import useDebounce from "src/utils/helpers/useDebounce"
import { ComboboxNotFoundText } from "../SearchBox.styles"
import Xclear from "./Xclear"
import CompanyLogo from "src/views/components/GeneralLogo/CompanyLogo"

interface Props {
  style_type: "vacancy" | "main"
  handeKeywordOptionClick: any
  performSearch: any
  query: string
  setQuery: any
}

const KeywordSearchDropdown = ({ style_type, query, setQuery, handeKeywordOptionClick, performSearch }: Props) => {
  const router = useRouter()

  useEffect(() => {
    setQuery(router.query.keyword as string)
  }, [router.query.keyword])

  const comboBtn = useRef<HTMLButtonElement | null>(null)

  const debouncedQuery = useDebounce(query, 300)

  const { isLoading, keywordSearchData } = useSearch({ keyword: debouncedQuery })
  const keywords = keywordSearchData?.keywords
  const company_keywords = keywordSearchData?.company_keywords
  const handleBtnClick = () => {
    comboBtn.current?.click()
  }

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handeKeywordOptionClick({ type: "keyword", value: query })
      performSearch(query)
    }
  }

  const clear = () => {
    setQuery("")
    handleBtnClick()
    handeKeywordOptionClick({ type: "keyword", value: "" })

    if (router.pathname.includes("vacancy")) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, keyword: "" }
      })
    }
  }

  const onFocusOut = () => {
    if (query && query.length) {
      // handleChange(query)
    }
  }

  const handleChange = (option: any) => {
    if (router.pathname.includes("vacancy")) {
      const currentQuery = { ...router.query, keyword: "", company_id: "" }

      if (option.type === "company") {
        currentQuery["company_id"] = option.company_id
        setQuery(option.value)
      }

      if (option.type === "keyword") {
        currentQuery["keyword"] = option.value
        setQuery(option.value)
      }

      router.push({
        pathname: router.pathname,
        query: currentQuery
      })
    } else {
      setQuery(option.value)
    }
  }

  const renderOptionsPopup = (open: boolean) => {
    // if (debouncedQuery === '') {
    //   return (
    //     <>
    //       <Typography type="h5" weight="medium" className="mb-4 block">
    //         მოთხოვნადი
    //       </Typography>

    //       <div className="flex flex-wrap gap-x-3 gap-y-3">
    //         {indemand_keywords.map(option => {
    //           const isSelected = selectedKeywords.includes(option)

    //           return (
    //             <Combobox.Option
    //               key={option}
    //               value={option}
    //               onClick={() => {
    //                 setSelectedKeywords(
    //                   isSelected ? [...selectedKeywords.filter(e => e !== option)] : [...selectedKeywords, option]
    //                 )
    //                 handleChange(
    //                   isSelected ? [...selectedKeywords.filter(e => e !== option)] : [...selectedKeywords, option]
    //                 )
    //                 setQuery('')
    //               }}
    //             >
    //               <Button
    //                 size="normal"
    //                 intent="outline_muted"
    //                 rounded="full"
    //                 weight="regular"
    //                 className={cx(isSelected && 'bg-secondary-100 text-white')}
    //                 text={option}
    //               />
    //             </Combobox.Option>
    //           )
    //         })}
    //       </div>
    //     </>
    //   )
    // }
    if (
      debouncedQuery !== "" &&
      keywords &&
      keywords.length === 0 &&
      company_keywords &&
      company_keywords.length === 0
    ) {
      return <ComboboxNotFoundText>ვაკანსია ვერ მოიძებნა</ComboboxNotFoundText>
    }

    const combinedOptions = [
      ...(company_keywords || []).map((option: any) => ({
        type: "company",
        value: option.company_title,
        key: option.company_id,
        has_logo: option.has_logo,
        company_id: option.company_id
      })),
      ...(debouncedQuery !== ""
        ? (keywords || []).map((option: any, idx: number) => ({
            type: "keyword",
            value: option,
            key: option + idx
          }))
        : [])
    ]

    return combinedOptions.map((option: any) => (
      <Combobox.Option
        value={option.value}
        key={option.key}
        className={cx("flex cursor-pointer items-center gap-3 rounded-2xl px-4 py-3 hover:bg-neutral-5")}
        onClick={() => {
          handeKeywordOptionClick(option)
          handleChange(option)
          comboBtn.current?.click()
        }}
      >
        {option.type === "company" && (
          <div className="relative h-8 w-8 overflow-hidden rounded-full border border-neutral-40">
            <CompanyLogo has_logo={option.has_logo} logo_version={0} company_id={option.company_id} />
          </div>
        )}
        <Typography type="text">{option.value}</Typography>
      </Combobox.Option>
    ))
  }

  return (
    <Combobox as={"div"} multiple className="keyword-dropdown relative flex-1">
      {({ open }) => (
        <>
          <Combobox.Button
            ref={comboBtn}
            className={"relative flex h-full w-full cursor-pointer items-center pl-5 focus:outline-none md:pl-8"}
            // className='hidden'
          >
            {/* Icon or Loader */}
            {isLoading && query !== "" ? (
              <div className="searchspinner" />
            ) : (
              <Image src={"/images/work_outline_purple.svg"} width={24} height={24} className="mr-2 " alt={"work"} />
            )}

            <Combobox.Input
              autoComplete="off"
              placeholder={"ძებნა"}
              onClick={handleBtnClick}
              onKeyDown={handleEnterPress} // Add event listener here
              className={cx(
                "line-clamp-1 w-full transform cursor-pointer overflow-hidden bg-inherit py-1 pr-2 text-2sm text-secondary-100 placeholder:font-tbcx-regular placeholder:text-2sm placeholder:text-secondary-50 md:border-r",
                style_type === "vacancy" && "border-neutral-40"
              )}
              onChange={e => {
                setQuery(e.target.value)
              }}
              value={query}
              displayValue={_ => query}
              onBlur={onFocusOut}
            />
          </Combobox.Button>
          <div onClick={clear}>
            <Xclear show={!!debouncedQuery} clear={clear} />
          </div>
          <Transition
            show={!isLoading && open}
            as={"div"}
            className={cx("absolute z-10 mt-2.5 md:left-2", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-2"
            enterTo="opacity-100 translate-y-0"
          >
            {debouncedQuery && (
              <Combobox.Options
                className={` flex max-h-[400px] cursor-default flex-col overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box  ${
                  !!debouncedQuery ? "w-[280px] p-2.5 md:w-[480px]" : "w-[280px] p-6 md:w-[1014px]"
                }`}
              >
                {renderOptionsPopup(open)}
              </Combobox.Options>
            )}
          </Transition>
        </>
      )}
    </Combobox>
  )
}

export default KeywordSearchDropdown
