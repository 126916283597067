import { v4 as uuidv4 } from "uuid"
import {
  optionsExpLevel,
  optionsEducationLevel,
  optionsHireType,
  optionsEmploymentType,
  optionsJobType
} from "./phisical-register-data"

export interface JobTitle {
  id: number
  title: string
}
export interface FilterSelectProps {
  id: number
  label: string
  value: string | number
}

export const filter_experience = [
  { id: 1, title: "ჩემზე მორგებული" },
  { id: 2, title: "თარიღი კლებადი" },
  { id: 3, title: "თარიღი ზრდადი" },
  { id: 4, title: "ხელფასი კლებადი" },
  { id: 5, title: "ხელფასი ზრდადი" }
]

export const searchbox_filter_generator = [
  {
    id: uuidv4(),
    title: "გამოცდილება და განათლება",
    subsections: [
      {
        id: uuidv4(),
        title: "მუშაობის გამოცდილება",
        filter_name: "experience_time",

        options: [
          {
            id: uuidv4(),
            label: "გამოცდილების გარეშე",
            value: null
          },
          {
            id: uuidv4(),
            label: "0-2 წელი",
            value: ["1", "2"]
          },
          {
            id: uuidv4(),
            label: "2-5 წელი",
            value: ["3", "4", "5"]
          },
          {
            id: uuidv4(),
            label: "5-10 წელი",
            value: ["6", "7", "8", "9", "10"]
          },
          {
            id: uuidv4(),
            label: "10+ წელი",
            value: ["11"]
          }
        ]
      },
      {
        id: uuidv4(),
        title: "გამოცდილების დონე",
        filter_name: "experience_levels",

        options: optionsExpLevel
      },
      {
        id: uuidv4(),
        title: "განათლება",
        filter_name: "educations",

        options: optionsEducationLevel
      }
    ]
  },

  {
    id: uuidv4(),
    title: "დასაქმება",
    subsections: [
      {
        id: uuidv4(),
        title: "დაქირავების ტიპი",
        filter_name: "hire_types",
        options: optionsHireType
      },
      {
        id: uuidv4(),
        title: "განაკვეთი",
        filter_name: "employement_types",
        options: optionsEmploymentType
      },
      {
        id: uuidv4(),
        title: "მუშაობის ტიპი",
        filter_name: "job_types",
        options: optionsJobType
      }
    ]
  }
]

// export const category_titles: JobTitle[] = [
//   { id: 1, title: 'Director' },
//   { id: 2, title: 'Chef' },
//   { id: 3, title: 'Manager' },
//   { id: 4, title: 'Model' },
//   { id: 5, title: 'Carpenter' },
//   { id: 6, title: 'Blacksmith' },
//   { id: 7, title: 'Web Developer' },
//   { id: 8, title: 'UX/UI Designer' },
//   { id: 9, title: 'Mobile Developer' },
//   { id: 10, title: 'Software Architect' },
//   { id: 11, title: 'Backend Developer' },
//   { id: 12, title: 'Frontend Developer' },
//   { id: 13, title: 'DevOps' },
//   { id: 14, title: 'Manager' }
// ]
